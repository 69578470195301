




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import VueMultiselect from 'vue-multiselect';
import { Prop } from 'vue-property-decorator';
import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import CommonFormElement, { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import JwlButton from '@/components/JwlButton.vue';
import { Submission } from '@/store/interface/Grade';
import { RunTask } from '@/store/interface/Task';

const LmsCourseDocument = () => import('./LmsCourseDocument.vue');

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

const STATUS_ERROR = -1;
const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;

@Component({
  components: {
    FilePond,
    CommonFormElement,
    JwlButton,
    LmsCourseDocument,
    JwlSubmitRow,
    VueMultiselect,
  },
})
export default class JwlGradeAction extends Vue {
  $refs!: {
    filePond: VueFilePondComponent;
    studentGradeForm: HTMLFormElement;
  }

  @Prop(Object)
  submission!: Submission;

  @Prop(Object)
  task!: RunTask;

  localSubmission: Submission|null = null;
  initialAttendance = false;
  initialComment = '';
  initialScore: SelectOption | null = null;
  error: string | null = null;
  submitStatus = STATUS_NOTHING;

  submitGrade (andPublish = false): void {
    if (this.submitStatus !== STATUS_SUBMITTING && this.$refs.studentGradeForm?.reportValidity()) {
      this.submitStatus = STATUS_SUBMITTING;
      this.error = null;
      const FD = new FormData(this.$refs.studentGradeForm);
      const courseCode = this.$route.params.course;
      const taskCode = this.$route.params.task;
      const { lmsId } = this.$route.params;

      if (this.$refs.filePond) {
        this.$refs.filePond.getFiles().forEach((filePondFile) => {
          FD.append('rectifiedFile[]', filePondFile.file);
        });
      }

      if (andPublish) {
        FD.append('publish', 'true');
      }

      this.$store.dispatch('postData', {
        url: `gradebook/${courseCode}/task/${taskCode}/${lmsId}`,
        formData: FD,
      }).then((data) => {
        this.submission.rectifiedFile = data.rectifiedFile;
        this.submitStatus = STATUS_SUCCESS;
        this.$refs.filePond?.removeFiles();
        if (andPublish) {
          this.$emit('grade-published', data.grade);
        } else {
          this.$emit('grade-updated');
        }
        setTimeout(() => {
          this.submitStatus = STATUS_NOTHING;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.submitStatus = STATUS_ERROR;
      });
    }
  }

  updateData (data: Submission): void {
    if (this.localSubmission) {
      this.localSubmission.rectifiedFile = data.rectifiedFile;
    }
  }

  get scores (): SelectOption[] {
    const options = [];

    const maxScore = this.task.maxScore || 0;

    for (let option = 0; option <= maxScore; option += 1) {
      if (this.task.halfScore && option > 0) {
        const halfScore = option - 0.5;
        options.push({
          id: halfScore.toString(),
          label: this.$tc('unit.task.gradePoint', halfScore),
        });
      }
      options.push({
        id: option.toString(),
        label: this.$tc('unit.task.gradePoint', option),
      });
    }

    return options;
  }

  get deleteUrl (): string {
    const { lmsId, course, task } = this.$route.params;
    return `gradebook/${course}/task/${task}/${lmsId}`;
  }

  created (): void {
    this.localSubmission = this.submission;
    if (this.submission?.grade) {
      const submittedGrade = this.submission.grade;

      if (submittedGrade.attended) {
        this.initialAttendance = submittedGrade.attended;
      }

      if (submittedGrade.score) {
        const gradeScore = submittedGrade.score.toString();
        const tmpScore = this.scores.find((scoresScore) => scoresScore.id === gradeScore);
        if (tmpScore) {
          this.initialScore = tmpScore;
        }
      }

      if (submittedGrade.comment) {
        this.initialComment = submittedGrade.comment;
      }
    }
  }
}
